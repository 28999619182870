<script>
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable prettier/prettier */
</script>
<template>
  <div>
    <v-tooltip top>
      <template v-slot:activator="{ on }">
        <v-btn v-on="on" icon @click="dialog = true">
          <v-icon :color="color">mdi-email </v-icon>
        </v-btn>
      </template>
      <span>Send Email</span>
    </v-tooltip>
    <v-dialog
      v-model="dialog"
      persistent
      :overlay="false"
      max-width="700px"
      transition="dialog-transition"
    >
      <v-card>
        <v-card-title class="blue darken-1 white--text" primary-title>
          Send email to:
        </v-card-title>
        <v-card-subtitle class="blue darken-1 white--text d-flex flex-column">
          <span>Name: {{ fullname }}</span>
          <span>Email: {{ user.email }}</span>
        </v-card-subtitle>
        <v-card-text>
          <div>
            <v-switch inset v-model="adjunct" label="Attach files"></v-switch>
            <template v-if="adjunct">
              <v-file-input
                v-model="files"
                placeholder="Upload your documents"
                label="Files"
                multiple
                counter
                show-size
                prepend-icon="mdi-paperclip"
              >
                <template v-slot:selection="{ text }">
                  <v-chip small label color="primary">
                    {{ text }}
                  </v-chip>
                </template>
              </v-file-input>
            </template>
          </div>
          <v-text-field
            :rules="[rules.email]"
            v-model="cc"
            outlined
            dense
            label="CC"
          ></v-text-field>
          <v-text-field
            v-model="subject"
            :rules="[rules.maxCaracters]"
            outlined
            dense
            counter="25"
            label="Subject"
          ></v-text-field>
          <v-textarea
            outlined
            name="input-7-4"
            label="Text"
            v-model="body"
          ></v-textarea>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text color="error" @click="cancelSend">Cancel</v-btn>
          <v-btn
            :disabled="invalidSend || loading"
            text
            color="primary"
            @click="confirmSend"
            >Send</v-btn
          >
        </v-card-actions>
        <v-overlay absolute :value="loading">
          <v-progress-circular indeterminate size="64"></v-progress-circular>
        </v-overlay>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import { apiBaseUrl } from "@/enviorment";
import { getAPI } from "@/api/axios-base";
import { notifyError } from "../Notification";
import rules from "@/components/account/rules";
export default {
  name: "email-send",
  props: {
    user: Object,
    color: {
      type: String,
      default: () => "#ffffff",
    },
  },
  data() {
    return {
      rules: {
        ...rules,
        maxCaracters: (v) => v.length >= 5 || this.$t("min5Chars"),
      },
      dialog: false,
      files: [],
      filesuuid: [],
      loading: false,
      body: "",
      cc: "",
      adjunct: false,
      subject: "",
    };
  },
  computed: {
    fullname() {
      if (this.user == undefined || this.user == null) {
        return "";
      }
      if (this.user.name == undefined) {
        return this.user.fullname;
      }
      return this.user.name + " " + this.user.lastName;
    },
    invalidSend() {
      const pattern =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      if (
        this.cc != "" &&
        this.cc != undefined &&
        this.cc != null &&
        !pattern.test(this.cc)
      ) {
        return true;
      }

      if (
        this.adjunct == true &&
        (this.files.length == 0 ||
          this.body == "" ||
          this.subject == "" ||
          this.subject.length < 5)
      ) {
        return true;
      }
      if (this.body == "" || this.subject == "" || this.subject.length < 5) {
        return true;
      }
      return false;
    },
  },
  watch: {
    adjunct(val) {
      if (!val) {
        this.files = [];
      }
    },
    filesuuid(val) {
      if (val.length != 0 && val.length == this.files.length) {
        this.sendEmail();
      }
    },
  },
  methods: {
    async confirmSend() {
      this.loading = true;
      if (this.adjunct) {
        await this.uploadImages();
      } else {
        this.sendEmail();
      }
    },
    async sendEmail() {
      let bodyreq = {
        fullname: this.fullname,
        email: this.user.email,
        cc: this.cc,
        subject: this.subject,
        body: this.body,
      };
      if (this.adjunct) {
        await this.uploadImages();
        bodyreq = { ...bodyreq, attacthments: this.filesuuid };
      }
      if (bodyreq.cc == "" || bodyreq.cc == null) {
        delete bodyreq.cc;
      }
      let url = "/patient/sendGenericEmail";
      getAPI
        .post(url, bodyreq)
        .then((res) => {
          this.cancelSend();
        })
        .catch((error) => {
          this.loading = false;
          let mess = error.response.data.message.toString();
          notifyError(error.response.data, `An error occurred: ${mess}`);
        });
    },
    cancelSend() {
      this.dialog = false;
      this.files = [];
      this.filesuuid = [];
      this.body = "";
      this.cc = "";
      this.adjunct = false;
      this.subject = "";
      this.loading = false;
    },
    async uploadImages() {
      this.files.forEach(async (element) => {
        const formData = new FormData();
        formData.append("file", element);
        try {
          const res = (
            await getAPI.post(
              apiBaseUrl + "/social/uploadAttachmentFiles",
              formData
            )
          ).data;

          this.filesuuid.push(res.uuid);
        } catch (error) {
          let mess = error.response.data.message.toString();
          notifyError(error.response.data, `An error occurred: ${mess}`);
        }
      });
    },
  },
};
</script>
<style lang=""></style>
<i18n>
{
  "en": {    
    "min5Chars": "The subject must have 5 or more letters"
     },
  "es": {    
    "min5Chars": "El asunto debe tener 5 o mas letras"     
  }
}
</i18n>
